import axios from 'axios';

let base_url = null;

if (process.env.NODE_ENV === 'development') {
    base_url = process.env.REACT_APP_DEV_API;
} else if (process.env.NODE_ENV === 'production') {
    base_url = process.env.REACT_APP_PROD_API;
}

let instance = axios.create({
    baseURL: base_url
});

/**
 * Injecting token to axios instance
 */
instance.interceptors.request.use(config => {
    let token = window.localStorage.getItem('token')

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    } else {
        config.headers.Authorization = null;
    }

    return config;
});

/**
 * Intercepting axios Unauthorized access
 */
instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    let status = null;

    if (error.response) {
        if (error.response.status) {
            status = error.response.status;
        }
    }

    if (401 === status) {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
        window.location.reload();
    } else {
        return Promise.reject(error);
    }
});

export default instance;
