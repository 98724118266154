import React from 'react';
import { useQuery, useMutation } from '../../../../context/hazumi/hooks';

import { FaEdit, FaTrashAlt } from 'react-icons/fa'
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

const OffreList = () => {
    const { data, refetch } = useQuery('resource/offre/list');
    const offres = data?.offres ?? null;

    const [deleteOffre] = useMutation('api/offre/delete');

    const handleDelete = (event, offre) => {
        Swal.fire({
            title: '<strong>Supprimer <u>l\'offre</u></strong>',
            icon: 'question',
            html:
                'Voulez-vous vraiment supprimer <b>l\'offre</b>?',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
        }).then((result) => {
            const el = event.target;
            el.disabled = true;
            const elHtml = el.innerHTML;
            el.innerHTML = "Suppression..."
            if (result.isConfirmed) 
                return deleteOffre(offre).then(response => {
                    if (response.status === 200) {
                        el.disabled = false;
                        el.innerHTML = elHtml
                        toast.success('Offre supprimée avec succès', {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            
                        });
                        refetch();
                    }
                })
        })
    }

    return (
        <div>
            <div className="bg-white p-8 w-full h-screen">
                <div className=" flex items-center justify-between pb-6">
                    <div>
                        <h2 className="text-gray-600 font-semibold">Offres</h2>
                        <span className="text-xs">Toutes les offres disponibles</span>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="lg:ml-40 ml-10 space-x-8">
                            <NavLink to="/offres/create">
                                <button
                                    className="bg-gray-100 text-gray-600 px-4 py-2 rounded-md text-white font-semibold tracking-wide cursor-pointer">
                                    Nouvelle offre
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
                        
                <div>
                    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                            <table className="min-w-full leading-normal">
                                <thead>
                                    <tr>
                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                            #ID 
                                        </th>
                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                            Message 
                                        </th>
                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                            Lien vers
                                        </th>
                                        <th
                                            className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { !offres || offres?.length <= 0 ? (
                                        <tr>
                                            <td colSpan={3} align="center" className="px-5 py-4 border-b border-gray-200 bg-white text-sm">Vide pour le moment</td>
                                        </tr>
                                    ) : null }
                                    { offres && offres.map((offre, index)=>
                                        <tr key={index}>
                                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                { offre.id }
                                            </td>
                                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <div className="flex items-center">
                                                    <div className="ml-3">
                                                        <p className="text-gray-900 whitespace-no-wrap">
                                                        { offre.message }
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <p className="text-gray-900 whitespace-no-wrap">{ offre.url }</p>
                                            </td>
                                            <td>
                                                <div className="flex">
                                                    <NavLink
                                                        to={`/offres/detail/${offre.id}`}
                                                        className="mr-2">
                                                        <button
                                                            className="button button-primary button-small">
                                                            <FaEdit className="mr-2" />
                                                            Modifier
                                                        </button>
                                                    </NavLink>

                                                    <button
                                                        onClick={(event) => handleDelete(event, offre)}
                                                        className="button button-danger button-small">
                                                        <FaTrashAlt className="mr-2" />
                                                        Supprimer
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ) }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OffreList