import React from 'react'
import SonCreate from '../SonCreate/SonCreate';
import { useQuery, useMutation } from '../../../../context/hazumi/hooks';
import ReactAudioPlayer from 'react-audio-player';
import { FaTrashAlt } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

const SonList = () => {
    const [modal, toggleModal] = React.useState(false);
    const { data, refetch } = useQuery('resource/son/list');

    const sons = data?.sons ?? [];

    const [deleteSon] = useMutation('api/son/delete');

    const handleDelete = (event, son) => {
        Swal.fire({
            title: '<strong>Supprimer <u>le son</u></strong>',
            icon: 'question',
            html:
                'Voulez-vous vraiment supprimer <b>le son</b>?',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
        }).then((result) => {
            const el = event.target;
            el.disabled = true;
            const elHtml = el.innerHTML;
            el.innerHTML = "Suppression..."
            if (result.isConfirmed) 
                return deleteSon(son).then(response => {
                    if (response.status === 200) {
                        el.disabled = false;
                        el.innerHTML = elHtml
                        toast.success('Son supprimé avec succès', {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            
                        });
                        refetch();
                    }
                })
        })
    }

    return (
        <div>
            <div className="bg-white p-8 w-full h-screen">
                <div className=" flex items-center justify-between pb-6">
                    <div>
                        <h2 className="text-gray-600 font-semibold">Sons</h2>
                        <span className="text-xs">Tous les sons disponibles</span>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="lg:ml-40 ml-10 space-x-8">
                            <button className="bg-gray-100 text-gray-600 px-4 py-2 rounded-md text-white font-semibold tracking-wide cursor-pointer"
                                onClick={() => toggleModal(true)}>
                                Nouveau son
                            </button>
                        </div>
                    </div>
                </div>
                
                { modal && <SonCreate toggleModal={toggleModal} onSuccess={() => {
                   refetch();
                   toggleModal(false);
                }}/>}
                        
                <div>
                    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                            <table className="min-w-full leading-normal">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>
                                            Nom 
                                        </th>
                                        <th>
                                            Répértoire
                                        </th>
                                        <th>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { !sons || sons?.length <= 0 ? (
                                        <tr>
                                            <td colSpan={4} align="center" className="px-5 py-4 border-b border-gray-200 bg-white text-sm">Vide pour le moment</td>
                                        </tr>
                                    ) : null }
                                    { sons && sons.map(son => {
                                        return (<tr>
                                            <td>
                                                <div className="thumbnail">
                                                    <ReactAudioPlayer
                                                        src={son.sonUrl}
                                                        controls
                                                        />
                                                </div>
                                            </td>
                                            <td>{ son.nom }</td>
                                            <td>{ son.sonPath }</td>
                                            <td>
                                                <button
                                                    onClick={(event) => handleDelete(event, son)}
                                                    className="button button-danger button-small">
                                                    <FaTrashAlt className="mr-2" />
                                                    Supprimer
                                                </button>
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default SonList