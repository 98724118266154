import React from 'react'
import Sidebar from '../../components/Sidebar/Sidebar';
import OffreList from './partials/OffreList/OffreList';

const Offre = () => {
    return (
        <div className=''>
            <div className="h-screen w-screen flex bg-gray-200">
                <Sidebar />
                <section className='w-full'>
                    <OffreList />
                </section>
            </div>
        </div>
    )
}

export default Offre