import React from 'react'
import Sidebar from '../../components/Sidebar/Sidebar';
import { useParams } from 'react-router-dom';
import { useQuery } from '@/context/hazumi/hooks/useQuery';
import ImageForm from './partials/ImageForm/ImageForm';
import LoadingAnimation from '@/components/LoadingAnimation/LoadingAnimation';

const ImageCreate = () => {
    const { imageId } = useParams();

    const { data, loading, refetch } = useQuery(`/api/images/detail/${imageId}`, {
        fetchCondition: parseInt(imageId) > 0
    });

    return (
        <div className=''>
            <div className="h-screen w-screen flex bg-gray-200">
                <Sidebar />
                <section className="w-full bg-white flex justify-center items-start">
                    <div className="mt-8 border border-gray-200 p-10 rounded-lg">
                        { loading ? <LoadingAnimation /> : <ImageForm refetch={refetch} defaultValues={data}/> }
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ImageCreate