import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '../../../../context/hazumi/hooks/useMutation';
import Swal from 'sweetalert2';

const  MembreCreate = ({ onSuccess, toggleModal }) => {
    const { register, handleSubmit, reset} = useForm();
    const [saveMember, {fusion}] = useMutation('api/member/create');

    const onSubmit = (data) =>{
        saveMember(data).then(response => {
            if (response.status === 201) {
                reset();
                if (typeof onSuccess === 'function') onSuccess();
                Swal.fire({
                    icon: 'success',
                    text:'Le membre a été créé avec succès'
                });
            }
        })
    }

    return (
        <div className='flex justify-center w-full'>
            <form onSubmit={handleSubmit(onSubmit)} className='w-1/2 border border-gray-200 p-5 rounded-lg'>
                <div className="grid xl:grid-cols-2 xl:gap-6">
                    <div className="relative z-0 mb-6 w-full group">
                        <input type="text" name="nom" id="nom" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required 
                            {...register("nom", { required: true })}
                        />
                        <label htmlFor="nom" className="absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nom</label>
                    </div>
                    <div className="relative z-0 mb-6 w-full group">
                        <input type="text" name="prenom" id="prenom" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required
                            {...register("prenom", { required: true })}
                        />
                        <label htmlFor="prenom" className="absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Prenom</label>
                    </div>
                </div>
                <div className="grid xl:grid-cols-2 xl:gap-6">
                    <div className="relative z-0 mb-6 w-full group">
                        <input type="tel" name="tel" id="tel" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required 
                            {...register("telephone", { required: true })}
                        />
                        <label htmlFor="tel" className="absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Telephone</label>
                    </div>
                    <div className="relative z-0 mb-6 w-full group">
                        <input type="email" name="email" id="email" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required 
                            {...register("email", { required: true })}
                        />
                        <label htmlFor="email" className="absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email</label>
                    </div>
                </div>
                <div className='flex justify-between w-full'>
                    <button
                        type="button"
                        className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" onClick={() => toggleModal(false)}>
                        Annuler
                    </button>
                    <button 
                        {...fusion()}
                        type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center ">
                        Valider
                    </button>
                </div>
            </form>
        </div>
    )
}
export default MembreCreate;