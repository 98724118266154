import { useState, useMemo, createContext } from "react";

// Creating context for user
const UserContext = createContext();

// HOC for UserContext with default value
const UserProvider = ({ children }) => {
    const storedToken = window.localStorage.getItem('token') ?? null;
    const storedUser = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
    const [token, updateToken] = useState(storedToken);
    const [user, updateUser] = useState(storedUser);

    const setToken = (token) => {
        updateToken(token);
        window.localStorage.setItem('token', token);
    }

    const setUser = (user) => {
        updateUser(user);
        window.localStorage.setItem('user', JSON.stringify(user));
    }

    const value = useMemo(
        () => ({ 
            token,
            setToken,
            user,
            setUser
        }), 
        [token, user]
    );

    return (
        <UserContext.Provider value={value}>
            { children }
        </UserContext.Provider>)
}

export {
    UserProvider
}

export default UserContext;