import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '../../../../context/hazumi/hooks/useMutation';
import useUpload from '../../../../context/UploadPhoto/UploadPhoto/useUpload';
import Swal from 'sweetalert2';


const SonCreate = ({onSuccess, toggleModal}) => {
    const { register, handleSubmit, setValue, reset } = useForm();
    const [saveMember, { fusion }] = useMutation('api/son/create');

    const onSubmit = (data) => {
        saveMember(data).then(response => {
            if (response.status === 201) {
                reset();
                if (typeof onSuccess === 'function') onSuccess();
                Swal.fire({
                    icon: 'success',
                    text:'Le son a été ajouté avec succès'
                });
            }
        })
    }

    const { showUploader } = useUpload({
        folder: 'storages/sons',
        accept: '.mp3, .wav',
        config : {
            width: 125,
            height: 125
        },
        label: 'Parcourir le son',
        defaultValue:null,
        onUploaded: (values) => {
            const { id, fileName, filePath } = values;
            setValue('sonId', id, { shouldDirty: true });
            setValue('sonName', fileName, { shouldDirty: true });
            setValue('sonPath', filePath, { shouldDirty: true });
        }
    })

    return (
        <div className='flex justify-center w-full'>
            <form onSubmit={handleSubmit(onSubmit)} className='w-1/2 border border-gray-200 p-5 rounded-lg'>
                <div>
                    <div className="relative z-0 mb-6 w-full group">
                        <input
                            {...register("nom", { required: true })}
                             type="text" name="nom" id="lien"
                             className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                        <label
                            htmlFor="lien"
                            className="absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Nom du son
                        </label>
                    </div>
                </div>

                <div>
                    <div className="relative z-0 mb-6 w-full group">
                       {showUploader()}
                    </div>
                </div>
                
                <div className='flex justify-between w-full'>
                    <button
                        className="button button-danger" onClick={() => toggleModal(false)}>
                        Annuler
                    </button>
                    <button
                        type="submit"
                        className="button button-primary"
                        {...fusion()}>
                        Valider
                    </button>
                </div>
            </form>
        </div>
    )
}

export default SonCreate