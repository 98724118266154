import React from 'react';
import Sidebar from '../../../../components/Sidebar/Sidebar';
import { NavLink } from 'react-router-dom';
// import { useParams } from "react-router-dom";
// import { useQuery } from '../../../../context/hazumi/hooks/useQuery';

const MembreDetail = () => {

    // let params = useParams();
    // const memberId = params.memberId;
    // const { data } = useQuery('/membre/detail',{
    //     variables: {
    //         memberId
    //     }
    // });

    // const member = data?.member ?? {};
    return (
        <div className=''>
            <div className="h-screen w-screen flex bg-gray-200">
                <Sidebar />
                <section className=' flex items-center justify-center bg-gray-900 p-8 w-full h-screen'>
                    <div className="max-w-md w-full bg-white rounded p-6 space-y-4">
                        <div className="mb-4">
                            <p className="text-gray-600"> A propos </p>
                        </div>
                        <div>
                            <input className="w-full p-4 text-sm bg-gray-50 focus:outline-none border border-gray-200 rounded text-gray-600" type="text" placeholder="Nom"/>
                        </div>
                        <div>
                            <input className="w-full p-4 text-sm bg-gray-50 focus:outline-none border border-gray-200 rounded text-gray-600" type="text" placeholder="Prenom"/>
                        </div>
                        <div>
                            <input className="w-full p-4 text-sm bg-gray-50 focus:outline-none border border-gray-200 rounded text-gray-600" type="text" placeholder="Telephone"/>
                        </div>
                        <div>
                            <input className="w-full p-4 text-sm bg-gray-50 focus:outline-none border border-gray-200 rounded text-gray-600" type="email" placeholder="Email"/>
                        </div>
                        <div className='flex justify-between w-full '>
                            <NavLink to={`/members`}
                                className="w-full py-4 bg-red-600 hover:bg-red-700 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">
                                Sortie
                            </NavLink>
                            <button 
                                className="w-full py-4 bg-blue-600 hover:bg-blue-700 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">
                                Edit
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default MembreDetail