import './styles/app.scss';
import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import Auth from './pages/Auth/Auth.page.js';
import Image from './pages/Images/Image.page.js';
import Membre from './pages/Membres/Membre.page.js';
import MembreDetail from './pages/Membres/partials/MembreDetail/MembreDetail.partial.js';
import Offre from './pages/Offres/Offre.page.js';
import Son from './pages/Son/Son.page.js';
import UserContext from './store/UserContext';
import { AuthCheck } from './components/AuthCheck/AuthCheck';

import { useContext } from 'react';
import ImageCreate from './pages/Images/ImageCreate.page';
import OffreCreate from './pages/Offres/OffreCreate.page';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Settings from './pages/Settings/Settings';

const App = () => {
  const { token } = useContext(UserContext);

  return (
    <>
      { token ? (
        <>
          <ToastContainer />
          <BrowserRouter>
            <AuthCheck />
            <div>
                <Routes>
                  <Route exact path="/auth" element={<Auth/>}/>
                  <Route path="*" element={<Membre/>}/>
                  <Route exact path="/membres" element={<Membre/>} />
                  <Route exact path="/membres/:membreId" element={<MembreDetail/>} />
                  <Route exact path="/offres" element={<Offre/>} />
                  <Route exact path="/offres/create" element={<OffreCreate/>} />
                  <Route exact path="/offres/detail/:offreId" element={<OffreCreate/>} />
                  <Route exact path="/images" element={<Image/>} />
                  <Route exact path="/images/create" element={<ImageCreate/>} />
                  <Route exact path="/images/detail/:imageId" element={<ImageCreate/>} />
                  <Route exact path="/sons" element={<Son/>}/>
                  <Route exact path="/settings" element={<Settings/>}/>  
                </Routes>
            </div>    
          </BrowserRouter>
        </>
      ) : (
        <Auth />
      ) }
      
    </>
  )
}
export default App;