import React from 'react'
import Sidebar from '../../components/Sidebar/Sidebar';
import ImageList from './partials/ImageList/ImageList';


const Image = () => {
    return (
        <div className=''>
            <div className="h-screen w-screen flex bg-gray-200">
                <Sidebar />
                <section  className='w-full'>
                    <ImageList />
                </section>
            </div>
        </div>
    )
}

export default Image