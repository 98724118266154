import React from 'react'
import { useForm } from 'react-hook-form';
import { useMutation } from '../../../../context/hazumi/hooks/useMutation';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const OffreForm = (props) => {
    const navigate = useNavigate();

    const { register, handleSubmit, reset } = useForm();
    const [updateOffre, {fusion: fusionUpdate}] = useMutation(`api/offre/update`);
    const [saveOffre, {fusion: fusionCreate}] = useMutation(`api/offre/create`);

    const hasUpdate = props && props?.id;
    const params = {
        hasUpdate,
        fusion: props && props?.id ? fusionUpdate : fusionCreate,
        title: props && props?.id ? `Modification d'une offre` : `Création d'une nouvelle offre`,
    };

    const onSubmit = (data) => {
        if (hasUpdate)
            return updateOffre(data).then(response => {
                if (response.status === 200) {
                    navigate('/offres');
                    toast.success('Offre mise à jour avec succès', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        
                    });
                }
            })
        
        
        return saveOffre(data).then(response => {
            if (response.status === 201) {
                navigate('/offres');
                toast.success('Offre créée avec succès', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    
                });
            }
        })
    }

    React.useEffect(() => {
        if (hasUpdate) reset(props);
    }, [props, hasUpdate, reset])

    return (
        <div className="w-full">
            <div className="text-2xl">{ params.title }</div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-8 relative z-0 mb-6 w-full group">
                    <textarea 
                        {...register("message", { required: true })} 
                        type="text"
                        rows={4}
                        id="apropos"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"/>
                    <label htmlFor="apropos" className="absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Message</label>
                </div>

                <div className="relative z-0 mb-6 w-full group">
                    <input type="text"  {...register("url", { required: true })} id="lien" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=""/>
                    <label htmlFor="lien" className="absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Lien </label>
                </div>
                
                <div className='flex justify-between w-full'>
                    <NavLink to="/offres">
                        <button
                            type="button"
                            className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">
                            Annuler
                        </button>
                    </NavLink>
                    <button
                        {...params.fusion()}
                        type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">
                        Valider
                    </button>
                </div>
            </form>
        </div>
    )
}

export default OffreForm