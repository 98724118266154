import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '../../../../context/hazumi/hooks/useMutation';
import useUpload from '../../../../context/UploadPhoto/UploadPhoto/useUpload';
import Swal from 'sweetalert2';
import { NavLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom";


const ImageForm = (props) => {
    const navigate = useNavigate();

    const defaultValues = props?.defaultValues ?? {};
    const { register, handleSubmit, setValue, getValues, reset } = useForm();
    const [updateImage, {fusion: fusionUpdate}] = useMutation(`api/image/update`);
    const [saveImage, {fusion: fusionCreate}] = useMutation(`api/image/create`);

    const hasUpdate = defaultValues && defaultValues?.id;
    const params = {
        hasUpdate,
        fusion: hasUpdate ? fusionUpdate : fusionCreate,
        title: hasUpdate ? `Modification d'une image` : `Création d'une nouvelle image`,
    };

    const onSubmit = (data) => {
        if (hasUpdate)
            return updateImage(data).then(response => {
                if (response.status === 200) {
                    navigate('/images');
                    Swal.fire({
                        icon: 'success',
                        text:'L\'image a été mise à jour avec succès'
                    });
                }
            })

        saveImage(data).then(response => {
            if (response.status === 201) {
                navigate('/images');
                Swal.fire({
                    icon: 'success',
                    text:'L\'image a été ajouté avec succès'
                });
            }
        })
    }

    const { showUploader } = useUpload({
        folder: 'storages/images',
        config : {
            width: 125,
            height: 125
        },
        label: hasUpdate ? `Choisir la nouvelle image` : `Parcourir l'image`,
        defaultValue:null,
        onUploaded: (values) => {
            const { id, fileName, filePath, fileUrl } = values;
            setValue('imageId', id, { shouldDirty: true });
            setValue('imageName', fileName, { shouldDirty: true });
            setValue('imagePath', filePath, { shouldDirty: true });
            setValue('imageUrl', fileUrl, { shouldDirty: true });
        }
    })

    React.useEffect(() => {
        if (hasUpdate) reset(defaultValues);
    }, [defaultValues, hasUpdate, reset])

    const currentValues = getValues();

    return (
        <div className="w-full">
            <div className="text-2xl">{ params.title }</div>
            <form onSubmit={handleSubmit(onSubmit)} className="mt-8">
                <div>
                    {/* <div className="mb-8">
                        <img src={props.imageUrl} alt={props.imageName}/>
                    </div> */}
                    <div className="relative z-0 mb-6 w-full group">
                        <input
                            {...register("nom", { required: true })}
                             type="text" name="nom" id="lien"
                             className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                        <label
                            htmlFor="lien"
                            className="absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Nom de l'image
                        </label>
                    </div>
                    <div style={{width: '450px', marginBottom: '1.5rem'}}>
                        <img src={ currentValues.imageUrl } alt={currentValues.imageName}/>
                    </div>
                </div>

                <div>
                    <div className="relative z-0 mb-6 w-full group">
                       { showUploader() }
                    </div>
                </div>
                
                <div className='flex justify-between w-full'>
                    <NavLink to="/images">
                        <button
                            className="button button-danger">
                            Annuler
                        </button>
                    </NavLink>

                    <button
                        type="submit"
                        className="button button-primary"
                        {...params.fusion()}>
                        Valider
                    </button>
                </div>
            </form>
        </div>
    )
}

export default ImageForm