import React from 'react'
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from '../../context/hazumi/hooks';
import Sidebar from '../../components/Sidebar/Sidebar';
import { toast } from 'react-toastify';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';

const Settings = () => {
    const { data, loading } = useQuery('resource/settings/get');
    const { register, handleSubmit, reset } = useForm();

    const [updateSetting, { fusion }] = useMutation('api/settings/update');

    const onSubmit = (data) => {
        updateSetting(data).then(response => {
            if (response.status === 201) {
                toast.success('Texte mis à jour avec succès', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    
                });
            }
        })
    }

    const about_text = data?.about_text ?? ``;

    React.useEffect(() => {
        if (loading === false && about_text) reset({
            about_text
        });
    }, [about_text, loading, reset])

    return (
        <div className=''>
            <div className="h-screen w-screen flex bg-gray-200">
                <Sidebar />
                <section  className='w-full'>
                    <div>
                        <div className="bg-white p-8 w-full h-screen">
                            <div className=" flex items-center justify-between pb-6">
                                <div>
                                    <h2 className="text-gray-600 font-semibold">Parametres</h2>
                                    <span className="text-xs">Parametrage de l'application et du back-office</span>
                                </div>
                            </div>
                                    
                            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                                <form onSubmit={handleSubmit(onSubmit)} className="w-1/2 relative">
                                    { loading ? <LoadingAnimation style={{position: 'absolute', backgroundColor: 'rgba(255, 255, 255, .75)', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}/> : null }
                                    <div className="mt-8 z-0 mb-2 w-full group">    
                                        <label
                                            htmlFor="apropos"
                                            className="text-xl px-4 text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Contenu à propos de l'application
                                        </label>
                                        <textarea 
                                            {...register("about_text", { required: true })} 
                                            type="text"
                                            rows={4}
                                            id="apropos"
                                            className="block mt-1 py-2.5 px-4 w-full text-sm text-gray-900 bg-gray-100 border-0 border-b-2  border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"/>
                                        
                                    </div>
                                    <div className="px-4 text-gray-400 text-sm">&gt; Texte d'affichage au niveau de la page à propos</div>
                                    
                                    <div className="flex mt-4 justify-end">
                                        <button
                                            {...fusion()}
                                            className="button button-primary"
                                            type={`submit`}>
                                            Sauvegarder
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Settings