import React from 'react'
import Sidebar from '../../components/Sidebar/Sidebar';
import SonList from './partials/SonList/SonList';

const Son = () => {
    return (
        <div className=''>
            <div className="h-screen w-screen flex bg-gray-200">
                <Sidebar />
                <section  className='w-full'>
                    <SonList />
                </section>
            </div>
        </div>
    )
}

export default Son