import React from 'react'
import Sidebar from '../../components/Sidebar/Sidebar';
import MembreList from './partials/MembreList/MembreList.partials';

const  Membre = () => {

    return (
        <div className=''>
            <div className="h-screen w-screen flex bg-gray-200">
                <Sidebar />
                <section className='w-full'>
                    <MembreList />
                </section>
            </div>
        </div>
    )
}

export default Membre;
