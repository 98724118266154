import React from 'react';
import MembreCreate from '../MembreCreate/MembreCreate.partials';
import { useQuery } from '../../../../context/hazumi/hooks/useQuery';

import { CSVLink } from "react-csv";

const  MembreList = () => {

    const [modal, toggleModal] = React.useState(false);
    const { data, refetch } = useQuery('resource/member/list');

    const members = data?.members ?? [];

    const headers = [
        { label: "Nom", key: "nom" },
        { label: "Prenom", key: "prenom" },
        { label: "Email", key: "email" },
        { label: "Telephone", key: "telephone" }
    ];

    const CSVParams = {
        data: members,
        headers: headers,
        separator: ";",
        filename: `export_membres_${(new Date()).getFullYear()}.csv`
    };

    return (
        <div>
            <div className="bg-white p-8 w-full h-screen">
                <div className=" flex items-center justify-between pb-6">
                    <div>
                        <h2 className="text-gray-600 font-semibold">Membres</h2>
                        <span className="text-xs">Tous les membres</span>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="lg:ml-40 ml-10 space-x-8">
                            <button className="bg-gray-100 text-gray-600 px-4 py-2 rounded-md text-white font-semibold tracking-wide cursor-pointer"
                                onClick={() => toggleModal(true)}
                            >
                                Créer
                            </button>
                            <CSVLink {...CSVParams} className="bg-gray-100 text-gray-600 px-4 py-2 rounded-md text-white font-semibold tracking-wide cursor-pointer"
                            >
                                Export en CSV
                            </CSVLink>
                        </div>
                    </div>
                </div>
                
                {modal && <MembreCreate onSuccess={() => {
                    toggleModal(false);
                    refetch();
                }} toggleModal={toggleModal}/>}
                        
                <div>
                    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                            <table className="min-w-full leading-normal">
                                <thead>
                                    <tr>
                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                            Nom
                                        </th>
                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                            Prenom
                                        </th>
                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                            Email
                                        </th>
                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                            Telephone
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { !members || members?.length <= 0 ? (
                                        <tr>
                                            <td colSpan={5} align="center" className="px-5 py-4 border-b border-gray-200 bg-white text-sm">Vide pour le moment</td>
                                        </tr>
                                    ) : null }
                                    { members && members?.map((member, index) =>
                                        <tr key={index}>
                                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <div className="flex items-center">
                                                    <div className="ml-3">
                                                        <p className="text-gray-900 whitespace-no-wrap">
                                                            {member.nom}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <p className="text-gray-900 whitespace-no-wrap">{member.prenom}</p>
                                            </td>
                                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                    {member.email}
                                                </p>
                                            </td>
                                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                    {member.telephone}
                                                </p>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            {/* <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between          ">
                                <span className="text-xs xs:text-sm text-gray-900">
                                    Affichage de 1 à 4 sur 5 entrées
                                </span>
                                <div className="inline-flex mt-2 xs:mt-0">
                                    <button
                                        className="text-sm text-indigo-50 transition duration-150 hover:bg-indigo-500 bg-indigo-600 font-semibold py-2 px-4 rounded-l">
                                        Précédent
                                    </button>
                                    &nbsp; &nbsp;
                                    <button className="text-sm text-indigo-50 transition duration-150 hover:bg-indigo-500 bg-indigo-600 font-semibold py-2 px-4 rounded-r">
                                        Suivant
                                    </button>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default MembreList;
