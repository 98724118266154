import { FaCircleNotch } from 'react-icons/fa';

const LoadingAnimation = ({ 
        className = `p-10 text-gray-500 flex items-center`,
        message = `Chargement en cours`, 
        icon = <FaCircleNotch className="ml-2 spin-rotate"/>,
        style = {}
    }) => {
    return (
        <div className={className} style={style}>
            { message } { icon }
        </div>
    )
}

export default LoadingAnimation;