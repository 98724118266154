import React from 'react';
import axios from '@/axios';
import {
    useLocation
} from "react-router-dom";

export const AuthCheck = () => {
    const location = useLocation();

    const checkUser = () => {
        axios
            .post('/api/user/detail')
            .then(response => response);
    }
      
    React.useEffect(() => {
        checkUser();
    }, [location])
    
    return (
        <></>
    )
}