import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '../../context/hazumi/hooks/useMutation';

import UserContext from '../../store/UserContext';
import { useContext } from 'react';

const Auth = () => {
    const { setToken, setUser } = useContext(UserContext);
    const { register, handleSubmit } = useForm();
    const [fetchLogin, { fusion }] = useMutation('/api/login_check');

    const onSubmit = (data) => {
        fetchLogin(data).then(response => {
            if (response.status === 200) {
                const token = response.data?.token;
                const user = response.data?.user;

                setToken(token);
                setUser(user);
            }
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <section className="flex justify-center items-center h-screen bg-gray-100">
                <div className="max-w-md w-full bg-white rounded p-6 space-y-4">
                    <div className="mb-4">
                        <p className="text-gray-600">Connectez-vous</p>
                    </div>
                    <div>
                        <input
                            {...register('username')}
                            className="w-full p-4 text-sm bg-gray-50 focus:outline-none border border-gray-200 rounded text-gray-600" type="text" placeholder="Pseudo"/>
                    </div>
                    <div>
                        <input
                            {...register('password')}
                            type="password"
                            className="w-full p-4 text-sm bg-gray-50 focus:outline-none border border-gray-200 rounded text-gray-600" placeholder="Password"/>
                    </div>
                    <div>
                        <button
                            {...fusion()}
                            className="button button-primary">
                            Connecter
                        </button>
                    </div>
                </div>
            </section>
        </form>
    )
}


export default Auth;
