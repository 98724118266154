import React from 'react'
import { useQuery, useMutation } from '@/context/hazumi/hooks';
import { NavLink } from 'react-router-dom';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

const ImageList = () => {
    const { data, refetch } = useQuery('resource/image/list');
    const images = data?.images ?? [];

    const [deleteOffre] = useMutation('api/image/delete');

    const handleDelete = (event, offre) => {
        Swal.fire({
            title: '<strong>Supprimer <u>l\'image</u></strong>',
            icon: 'question',
            html:
                'Voulez-vous vraiment supprimer <b>l\'image</b>?',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
        }).then((result) => {
            const el = event.target;
            el.disabled = true;
            const elHtml = el.innerHTML;
            el.innerHTML = "Suppression..."
            if (result.isConfirmed) 
                return deleteOffre(offre).then(response => {
                    if (response.status === 200) {
                        el.disabled = false;
                        el.innerHTML = elHtml
                        toast.success('Image supprimée avec succès', {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            
                        });
                        refetch();
                    }
                })
        })
    }

    return (
        <div>
            <div className="bg-white p-8 w-full h-screen">
                <div className="flex items-center justify-between pb-6">
                    <div>
                        <h2 className="text-gray-600 font-semibold">Images</h2>
                        <span className="text-xs">Toutes les images disponibles</span>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="lg:ml-40 ml-10 space-x-8">
                            <NavLink to="/images/create">
                                <button className="bg-gray-100 text-gray-600 px-4 py-2 rounded-md text-white font-semibold tracking-wide cursor-pointer">
                                    Nouvelle image
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
                        
                <div>
                    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                            <table className="min-w-full leading-normal">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>
                                            Nom 
                                        </th>
                                        <th>
                                            Nom de l'image
                                        </th>
                                        <th>
                                            Répértoire
                                        </th>
                                        <th>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { !images || images?.length <= 0 ? (
                                        <tr>
                                            <td colSpan={5} align="center" className="px-5 py-4 border-b border-gray-200 bg-white text-sm">Vide pour le moment</td>
                                        </tr>
                                    ) : null }
                                    { images && images.map((image, index) => {
                                        return (<tr key={index}>
                                            <td>
                                                <div className="thumbnail">
                                                    <img src={ image.imageUrl } alt={image.imageName}/>
                                                </div>
                                            </td>
                                            <td>{ image.nom }</td>
                                            <td>{ image.imageName }</td>
                                            <td>{ image.imagePath }</td>
                                            <td>
                                                <div className="flex">
                                                    <NavLink
                                                        className="mr-2"
                                                        to={`/images/detail/${image.id}`}>
                                                        <button
                                                            className="button button-primary button-small">
                                                            <FaEdit className="mr-2" />
                                                            Modifier
                                                        </button>
                                                    </NavLink>

                                                    <button
                                                        onClick={(event) => handleDelete(event, image)}
                                                        className="button button-danger button-small">
                                                        <FaTrashAlt className="mr-2" />
                                                        Supprimer
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default ImageList